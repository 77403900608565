<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Solicitudes Transportes Interno De Fluidos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Funcionarios Frontera</li>
                  <li class="breadcrumb-item active">Tif</li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">Solicitudes</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-10"></div>
                  <div class="col-md-12">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal_export_solInte"
                        @click="limpiarExport()"
                        v-if="
                          $store.getters.can(
                            'funcionariofrontera.csSolicitudInterna.export'
                          )
                        "
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        v-if="
                          $store.getters.can(
                            'funcionariofrontera.csSolicitudInterna.create'
                          )
                        "
                        @click="create()"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                      <button
                        type="button"
                        class="btn bg-navy"
                        @click="getIndex()"
                      >
                        <i class="fas fa-sync-alt fa-spin"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-danger"
                        @click="limpiarFiltro()"
                      >
                        <i class="fas fa-broom"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body pb-0">
                <div class="row justify-content-center">
                  <div class="form-group col-md-2">
                    <label># Solicitud</label>
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      v-model="filtros.id"
                      @input="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label>Nombre Usuario</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="filtros.user"
                      @input="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Fecha</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="filtros.fecha"
                      @input="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Estado</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="filtros.estado"
                      @change="getIndex()"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="estado in listasForms.estados"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="card-body table-responsive p-0">
                <table
                  class="table table-bordered table-striped table-hover table-sm"
                  style="font-size: 0.9em"
                >
                  <thead class="thead bg-dark">
                    <tr class="text-center">
                      <th>#</th>
                      <th>Nombre Usuario</th>
                      <th>Fecha</th>
                      <th>Justificación</th>
                      <th>Observaciones</th>
                      <th>Estado</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr></tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
/* import axios from "axios"; */
import Loading from "../../../../components/Loading";
/* import pagination from "laravel-vue-pagination"; */

export default {
  name: "SolicitudIndex",

  components: {},

  data() {
    return {
      cargando: false,
      filtros: {},
      solicitudes: {},
      listasForms: {},
    };
  },

  methods: {},

  beforeMount() {},

  mounted() {},
};
</script>
